// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBWalAUrD7cXtvES8NaL6fL3M1wz_hp9Mw",
    authDomain: "feriadeteatrocr.firebaseapp.com",
    databaseURL: "https://feriadeteatrocr.firebaseio.com",
    projectId: "feriadeteatrocr",
    storageBucket: "feriadeteatrocr.appspot.com",
    messagingSenderId: "1092722408333",
    appId: "1:1092722408333:web:668a2f4c8b1e80a1981bc4",
    measurementId: "G-HPKL25VL5H"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);