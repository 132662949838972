import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Asegúrate de importar Bootstrap JS
import logo from '../logoc.svg';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';

const ErrorPage = () => {
    const [message, setMessage] = useState(localStorage.errorMessage);

    const handleLogout = async () => {
        try {
            await signOut(auth);
            localStorage.clear();
            window.location.href = '/';
        } catch (error) {
            console.error('Error cerrando sesión:', error);
        }
    };

    return (
        <div className="text-center">
            <main className="form-signin">
                <img className="mb-5" src={logo} alt="" width="270" />
                <p>{message ? message : 'Error. Cierra sesión e intentalo de nuevo.'}</p>
                <p><button className="w-100 btn btn-feria" type="button" onClick={handleLogout}>Salir</button></p>
                <br />
                <p className="mt-5 mb-3 text-muted">FTCYL &copy; 2024</p>
            </main>
        </div>
    );
};

export default ErrorPage;
