import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoginPage from './pages/acceso';
import ErrorPage from './pages/errorPage';
import UserPanel from './pages/auxiliarPanel';
import {auth} from './firebase';

function App() {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
    const [error, setError] = useState(false);

    const handleLogin = async (user) => {
        try {
            const response = await fetch("https://app-35tp5gjjza-ew.a.run.app/api/staff/acceso", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ uid: user.uid }),
            });

            const data = await response.json();
            if (response.ok && data.success) {
                const userInfo = { displayName: data.name, role: data.role, uid: user.uid, bearer: user.accessToken };
                localStorage.setItem("user", JSON.stringify(userInfo));
                console.log(`User Ok`);
                setUser(userInfo); // Actualiza el estado del usuario
            } else {
                setError(true);
                localStorage.setItem("errorMessage", "No tienes permiso para acceder.  Contacta con el administrador e inténtalo de nuevo más tarde.");
                throw new Error("Failed to fetch user role.");
            }
        } catch (error) {
            setError(true);
            localStorage.setItem("errorMessage", "Error recuperando datos de usuario. Contacta con el administrador e inténtalo de nuevo más tarde.");
        }
    };

    useEffect(() =>
        auth.onAuthStateChanged((user) => {
            if (user){
                user.getIdToken(/* forceRefresh */ true).then(function(idToken) {
                    console.log(`SessionRefresh Passed`);
                    handleLogin(user);
                  }).catch(function(error) {
                    // Handle error
                    setError(true);
                    localStorage.setItem("errorMessage", "Error al cargar los datos de usuario. Inicia sesión de nuevo.");
                  });
            }
        }), []);

    if (!error){
        if (user && user.role) {
            return <UserPanel />;
        } else {
            return <LoginPage onLogin={handleLogin} />;
        }
    }
    else{
        return <ErrorPage />
    }
}

export default App;
