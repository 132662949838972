import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Asegúrate de importar Bootstrap JS
import logo from '../logoc.svg';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';

const UserPanel = () => {
  const [userSession, setUserSession] = useState(null);
  const [apiData, setApiData] = useState([]);
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const userSessionData = JSON.parse(localStorage.getItem('user'));
    setUserSession(userSessionData);

    const fetchCuadranteData = async () => {
      try {
        const response = await fetch('https://app-35tp5gjjza-ew.a.run.app/api/staff/getCuadrante', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Bearer': userSessionData.bearer
          },
        });

        if (!response.ok) {
          setLoading(false);
          showNotification('Error al conectar con el servidor.', 'error');
          throw new Error('Error al obtener los datos de la API');
        }

        const data = await response.json();
        if (data.length <= 0) {
          showNotification('Horarios no disponibles. Contacta con Mario.', 'error');
        }
        setLoading(false);
        setApiData(data);
      } catch (error) {
        setLoading(false);
        showNotification('Error al obtener los datos. Por favor, inténtalo de nuevo más tarde.', 'error');
      }
    };

    if ((apiData.length <= 0) && (notification === null)){
      fetchCuadranteData();
    }
  }, []);

  const showNotification = (message, type) => {
    setNotification({ message, type });
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      localStorage.clear();
      window.location.href = '/';
    } catch (error) {
      console.error('Error cerrando sesión:', error);
      showNotification('Error cerrando sesión', 'error');
    }
  };

  const formatSpace = (a) => {
    const spaces = {
      teatronuevo: 'T.N. Fernando Arrabal',
      bolonia: 'Jardines de Bolonia',
      salatierra: 'Sala Tierra',
      sitios: 'Patio de Los Sitios',
      afecir: 'Espacio Afecir',
      herrasti: 'Plaza de Herrasti',
      misioneras: 'Salón de actos Misioneras Sta. Teresa',
      glorieta: 'Parque de la Glorieta',
      espacioenrosa: 'Espacio en Rosa',
      seminario: 'Patio Mayor Seminario',
      florida: 'Parque de La Florida',
      divier: 'Plaza del Buen Alcalde',
      sanpelayo: 'Zona Muralla - San Pelayo',
      castillo: 'Plaza del Castillo',
      'iti.damasoledesma': 'Itinerante: Salida de Plaza Dámaso Ledesma',
      'iti.sansalvador': 'Itinerante: Salida de Plaza San Salvador',
      'iti.castillo': 'Itinerante: Salida de la Plaza del Castillo',
      'iti.herrasti': 'Itinerante: Salida de la Plaza de Herrasti',
      'iti.plazaconde': 'Itinerante: Salida de Plaza del Conde',
      guardiasanpelayo: 'Puesto de Guardia de San Pelayo',
      siegaverde: 'Espacio Siega Verde',
      'iti.centro': 'Itinerante: Centro ciudad',
      iti: 'Itinerante',
      palaciodelosaguila: 'Palacio de Los Águila',
    };
    return spaces[a] || a;
  };

  const formatDay = (a) => {
    switch (parseInt(a, 10)) {
      case 20:
        return 'Martes 20';
      case 21:
        return 'Miércoles 21';
      case 22:
        return 'Jueves 22';
      case 23:
        return 'Viernes 23';
      case 24:
        return 'Sábado 24';
      default:
        return '';
    }
  };

  const generateOtherAuxiliaries = (currentUserName, auxiliariesArray) => {
    const filteredNames = auxiliariesArray.filter((name) => name !== currentUserName);
    return filteredNames.map((name, index) => (
      <span key={index} className="badge badge-aux-big">{name}</span>
    ));
  };
  return (
    <div>
      <nav className="navbar">
        <div className="container-fluid">
          <span className="navbar-brand">
            <img src={logo} alt="Logo" width="150" className="d-inline-block align-text-top" />
          </span>
          <div className="d-flex">
            {JSON.parse(localStorage.user).role === "master" ? <a class="btn btn-dark text-light" href="/admin/index.html">Gestionar</a> : ''}
            <button className="btn btn-feria" type="button" onClick={handleLogout}>
              Cerrar sesión
            </button>
          </div>
        </div>
      </nav>
      <div className="container-fluid mt-3" id="cuadrante-content">
        {loading ? <div className="mt-3 loading-area d-flex justify-content-center">
          <div className="spinner-border" role="status"></div>
        </div>
          : ''}
        {notification && (
          <div className="text-align-center">
            <b>{notification.message}</b>
          </div>
        )}
        <div className="row" id="results">
          {apiData.map((espectaculo) => (
            <div className="col-md-4" key={espectaculo.id}>
              <div className={`card mb-3 card-horario-${espectaculo.id}`}>
                <div className="row g-0">
                  <div className="card-body">
                    <h5 className="card-title card-dia">
                      {formatDay(espectaculo.dia)} a las {espectaculo.horas[0]} horas
                    </h5>
                    <p className="card-text card-espacio">
                      {formatSpace(espectaculo.espacio)} <i className="bi bi-stopwatch"></i> {espectaculo.duracion} min
                    </p>
                    <p className="text-muted card-inout">
                      Entrada {espectaculo.calculos.hora_llegada} h | Salida {espectaculo.calculos.hora_salida} h
                    </p>
                  </div>
                  <div className="accordion accordion-flush" id={`accordionFlushExample-${espectaculo.id}`}>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id={`ac1-${espectaculo.id}`}>
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse1-${espectaculo.id}`}
                          aria-expanded="false"
                          aria-controls={`collapse1-${espectaculo.id}`}
                        >
                          Información sobre el espectáculo
                        </button>
                      </h2>
                      <div
                        id={`collapse1-${espectaculo.id}`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`ac1-${espectaculo.id}`}
                        data-bs-parent={`#accordionFlushExample-${espectaculo.id}`}
                      >
                        <div className="accordion-body espectaculo-info">
                          <p className="espectaculo-info-pretitle">{espectaculo.cia}</p>
                          <p className="espectaculo-info-title">{espectaculo.titulo}</p>
                          <p>
                            <a
                              href={espectaculo.link}
                              className="espectaculo-info-link w-100 espectaculo-info-subtitle btn-feria btn"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Ver más
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    {userSession.role === "master" ? (
                      <div className="accordion-item">
                        <h2 className="accordion-header" id={`ac2-${espectaculo.id}`}>
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse2-${espectaculo.id}`}
                            aria-expanded="false"
                            aria-controls={`collapse2-${espectaculo.id}`}
                          >
                            Auxiliares
                          </button>
                        </h2>
                        <div
                          id={`collapse2-${espectaculo.id}`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`ac2-${espectaculo.id}`}
                          data-bs-parent={`#accordionFlushExample-${espectaculo.id}`}
                        >
                          <div className="accordion-body auxs">
                            {espectaculo.auxiliares.length > 0
                              ? generateOtherAuxiliaries("", espectaculo.auxiliares)
                              : 'No hay auxiliares asignados'}
                          </div>
                        </div>
                      </div>
                    ) : (
                      espectaculo.auxiliares.length > 1 && (
                        <div className="accordion-item">
                          <h2 className="accordion-header" id={`ac2-${espectaculo.id}`}>
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapse2-${espectaculo.id}`}
                              aria-expanded="false"
                              aria-controls={`collapse2-${espectaculo.id}`}
                            >
                              Otros auxiliares
                            </button>
                          </h2>
                          <div
                            id={`collapse2-${espectaculo.id}`}
                            className="accordion-collapse collapse"
                            aria-labelledby={`ac2-${espectaculo.id}`}
                            data-bs-parent={`#accordionFlushExample-${espectaculo.id}`}
                          >
                            <div className="accordion-body auxs">
                              {generateOtherAuxiliaries(userSession.displayName, espectaculo.auxiliares)}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UserPanel;
