// src/LoginPage.js
import React, { useState } from 'react';
import { auth } from '../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import '../App.css';
import logo from '../logoc.svg';

const LoginPage = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      onLogin(user);
    } catch (error) {
      setLoading(false);
      setError(handleError(error.message));
    }
  };

  const handleError = (e) => {
    switch (e) {
      case "Firebase: Error (auth/wrong-password).":
        return "Contraseña incorrecta";
      case "Firebase: Error (auth/user-not-found).":
        return "Correo electrónico incorrecto";
      default:
        return e;
    }
  }

  return (
    <div className="text-center">
      <main className="form-signin">
        <form id="login-form" onSubmit={handleSubmit}>
          <img className="mb-5" src={logo} alt="" width="270" />

          <div className="form-floating">
            <input type="text" className="form-control" id="email" placeholder="Correo electrónico" value={email} onChange={(e) => setEmail(e.target.value)} required />
            {/* <span className="input-group-text">@ftcyl.es</span> */}
            <label>Correo electrónico</label>
          </div>

          <div className="form-floating">
            <input type="password" className="form-control" id="password" placeholder="Contraseña" value={password} onChange={(e) => setPassword(e.target.value)} required /> <label>Contraseña</label>
          </div>

          <button className="w-100 btn btn-lg btn-feria" type="submit">Acceder a mi panel</button> {error &&
            <div id="error-message" className="mt-3 text-danger">{error}</div>}
        </form>
        {loading ? <div className="mt-3 loading-area d-flex justify-content-center">
          <div className="spinner-border" role="status"></div>
        </div>
          : ''}
        <p className="mt-5 mb-3 text-muted">FTCYL &copy; 2024</p>
      </main>
    </div>
  );
};

export default LoginPage;
